<template>
  <div class="banner-wrap d-flex align-center" :style="`height: ${bannerHeight}px`">
    <v-container style="height: 100%; display: flex; align-items: center;">
      <v-row class="justify-center align-center align-self-center" style="height: 100%;">
        <v-col cols="auto" class="text-left text-sm-left align-self-center justify-center">
          <!-- style="line-height: 72px;" -->
          <div class="text-1">
            간병인 요청 후 지원한 간병인의 프로필을 카톡으로 받아보세요!
          </div>
          <div class="text-2">해당 병원 보호자분들이 이용해 주시고 계십니다. ({{ month }}월 기준 주요 병원)</div>

          <div class="slider-wrapper" ref="slider">
            <div
              class="slider-item"
              v-for="hospital in hospitals"
              :key="hospital.id"
              :style="{ color: hospital.color }"
            >
              {{ hospital.name }}
            </div>
          </div>

          <v-btn
            v-show="!$vuetify.breakpoint.xs"
            x-large
            depressed
            color="#007AFF"
            class="white--text mt-2 mt-sm-6 mt-md-12"
            style="width: 240px; height: 60px;"
            @click="$router.push('/request').catch(() => {})"
          >
            <span class="font-weight-bold" style="font-size: 1.1rem;">간병인 요청하기</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios"
import dayjs from "dayjs"
export default {
  data() {
    return {
      isMobile: false,
      bannerHeight: 200,
      hospitals: [],
      month: dayjs().format("MM")
    }
  },
  beforeDestroy() {
    if (typeof window === "undefined") return
    window.removeEventListener("resize", this.onResize, { passive: true })
  },

  async mounted() {
    this.onResize()
    window.addEventListener("resize", this.onResize, { passive: true })
    const hospitalResponse = await axios.get("https://api2.thehelper.io/helper-bussiness-hospital")
    this.hospitals = hospitalResponse.data

    setInterval(() => {
      if (this.$refs.slider.scrollLeft + this.$refs.slider.clientWidth >= this.$refs.slider.scrollWidth) {
        this.$refs.slider.scrollTo(0, 0)
      } else {
        this.$refs.slider.scrollTo(this.$refs.slider.scrollLeft + this.$refs.slider.clientWidth / 3, 0)
      }
    }, 2000)
  },

  methods: {
    onResize() {
      // console.log(window.innerWidth)
      const breakPoint = this.$vuetify.breakpoint.thresholds.xs
      const minHeight = 200
      let height = window.innerWidth - breakPoint
      if (height < 0) {
        height = minHeight
      } else {
        height += minHeight
        if (height >= 530) {
          height = 530
        }
      }
      this.bannerHeight = height
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-wrap {
  position: relative;
  height: 100%;
  min-height: 120px;
  max-height: 530px;
  background: url("./assets/bg.jpg") center center no-repeat;
  background-size: cover;
  // background-size: contain;
  // background-color: linear-gradient(90deg, #DADBE1 0%, rgba(218, 219, 225, 0.81) 44.24%, rgba(218, 219, 225, 0) 98.77%);
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dadbe1+0,d6d4d7+51,d0ced4+100 */
  background-color: #dadbe1; /* Old browsers */
  background-color: -moz-linear-gradient(top, #dadbe1 0%, #d6d4d7 51%, #d0ced4 100%); /* FF3.6-15 */
  background-color: -webkit-linear-gradient(top, #dadbe1 0%, #d6d4d7 51%, #d0ced4 100%); /* Chrome10-25,Safari5.1-6 */
  background-color: linear-gradient(
    to bottom,
    #dadbe1 0%,
    #d6d4d7 51%,
    #d0ced4 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dadbe1', endColorstr='#d0ced4',GradientType=0 ); /* IE6-9 */

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0.3;
  }
}
.justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.subtitle {
  color: #4188c8;
}
.text-1 {
  color: white;
  z-index: 99;
  position: relative;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
}
.text-2 {
  color: white;
  z-index: 99;
  position: relative;
  font-weight: 700;
  font-size: 8px;
  text-align: center;
  margin-top: 5px;
}

.slider-wrapper {
  width: 340px;
  margin-top: 16px;
  position: relative;
  overflow-x: scroll;
  z-index: 99;
  display: flex;
  gap: 8px;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
.slider-wrapper::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.slider-item {
  scroll-snap-align: start;
  background-color: white;
  flex: 0 0 108px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 700;
}

@media only screen and (min-width: 600px) {
  .text-1 {
    font-size: 16px;
  }
  .text-2 {
    font-size: 12px;
  }

  .slider-wrapper {
    width: 420px;
  }

  .slider-item {
    flex: 0 0 134px;
  }
}

@media only screen and (min-width: 960px) {
  .text-1 {
    font-size: 24px;
  }
  .text-2 {
    font-size: 16px;
  }

  .slider-wrapper {
    width: 630px;
  }

  .slider-item {
    flex: 0 0 204px;
    height: 60px;
    font-size: 20px;
  }
}
</style>
